import React, { useEffect, useState } from 'react'

const MapComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [marker, setMarker] = useState(null)

  useEffect(() => {
    const initializeMap = () => {
      const mapElement = document.getElementById('map')
      const googleMap = new window.google.maps.Map(mapElement, {
        center: { lat: 37.37503003, lng: -6.04697812 },
        zoom: 16,
        mapId: 'AIzaSyB5fz - n99EfXNoBDr0MBcIjezkx_Sh99CQ', // Usar la variable de entorno
      })

      setMap(googleMap)

      window.google.maps
        .importLibrary('marker')
        .then(({ AdvancedMarkerElement }) => {
          const markerPosition = {
            lat: 37.37765195387147,
            lng: -6.029092691928486,
          }
          const newMarker = new AdvancedMarkerElement({
            map: googleMap,
            position: markerPosition,
          })
          setMarker(newMarker)

          // Centrar el mapa en la posición del marcador
          googleMap.setCenter(markerPosition)
        })
    }

    const loadGoogleMaps = () => {
      if (window.google && window.google.maps) {
        initializeMap()
      } else {
        setTimeout(loadGoogleMaps, 100)
      }
    }

    loadGoogleMaps()
  }, [])

  return (
    <div id="map" style={{ height: '450px', width: '90%' }}>
      {/* Mapa de Google Maps */}
    </div>
  )
}

export default MapComponent
