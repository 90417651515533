import { memo } from "react";

import * as S from "./styles";

const Button = () => {

  return (
    <S.Container>
    ENVIAR
    </S.Container>
  );
};

export default memo(Button);
