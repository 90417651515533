import styled from "styled-components";

export const Container = styled.button`
  width: 100px;
  height: 33px;
  border-radius: 10px;
  background: #446844;
  color: #ffffff;
  margin: 1rem;
  margin-left: 43%;
`;





