import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './styles.css'

export const ContactUs = () => {
  const form = useRef()
  const [num1] = useState(Math.floor(Math.random() * 10) + 1)
  const [num2] = useState(Math.floor(Math.random() * 10) + 1)
  const [captchaAnswer, setCaptchaAnswer] = useState('')
  const [captchaError, setCaptchaError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = (e) => {
    e.preventDefault()
    setIsModalOpen(true) // Abrir el modal
  }

  const handleCloseModal = () => {
    setIsModalOpen(false) // Cerrar el modal
    setCaptchaError('') // Reiniciar el mensaje de error si el modal se cierra
  }

  const handleCaptchaSubmit = () => {
    if (parseInt(captchaAnswer, 10) !== num1 + num2) {
      setCaptchaError('Respuesta incorrecta. Inténtalo de nuevo.')
      return
    }

    // CAPTCHA correcto, proceder a enviar el formulario
    emailjs
      .sendForm('service_8v3gwl7', 'template_rz7uqd7', form.current, {
        publicKey: '-5LSaO9DixrMLGZLS',
      })
      .then(
        () => {
          console.log('SUCCESS!')
          form.current.reset()
          setCaptchaAnswer('') // Reiniciar la respuesta del CAPTCHA
          handleCloseModal() // Cerrar el modal al enviar correctamente
        },
        (error) => {
          console.log('FAILED...', error.text)
        }
      )
  }

  return (
    <div>
      <form ref={form} onSubmit={handleOpenModal} className="field">
        <label></label>
        <input
          className="label"
          type="text"
          name="user_name"
          placeholder="Nombre..."
        />
        <label></label>
        <input
          className="label"
          type="email"
          name="user_email"
          placeholder="Email..."
        />
        <label></label>
        <input
          className="label"
          type="text"
          name="user_mobilenumber"
          placeholder="Teléfono de contacto..."
        />
        <label></label>
        <textarea className="input" name="message" placeholder="MENSAJE..." />

        {/* Botón de Enviar abre el modal en lugar de enviar directamente */}
        <button type="submit" className="styled-button">
          Enviar
        </button>
      </form>

      {/* Modal para el CAPTCHA */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Verificación de Seguridad</h3>
            <p>
              ¿{num1} + {num2}?
            </p>
            <input
              type="text"
              value={captchaAnswer}
              onChange={(e) => setCaptchaAnswer(e.target.value)}
              placeholder="Escribe tu respuesta aquí"
            />
            {captchaError && <p style={{ color: 'red' }}>{captchaError}</p>}
            <div className="modal-buttons">
              <button onClick={handleCaptchaSubmit} className="styled-button">
                Verificar y Enviar
              </button>

              <button onClick={handleCloseModal} className="cancel-button">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactUs
